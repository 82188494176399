var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "d-flex" }, [
    _c("div", { staticClass: "d-flex select-button" }, [
      _c(
        "div",
        {
          ref: "menuButton",
          staticClass: "customHeaderMenuButton",
          on: {
            click: function ($event) {
              return _vm.onSelectToggle($event)
            },
          },
        },
        [
          _c("font-awesome-icon", {
            staticClass: "selection-icon",
            attrs: {
              icon: _vm.selectedAll
                ? ["far", "square-check"]
                : _vm.selectedSome
                ? ["far", "square-minus"]
                : ["far", "square"],
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }