var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CompanyBadgeGroupModal", {
    attrs: {
      show: _vm.modalShow,
      companyList: _vm.companyList,
      isOptional: _vm.isOptional,
    },
    on: {
      "update:show": function ($event) {
        _vm.modalShow = $event
      },
      ok: _vm.companySelectorOk,
      cancel: _vm.companySelectorCancel,
      companyDeleted: _vm.companySelectorDeleted,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }