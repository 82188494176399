var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WebsitesModal", {
    attrs: {
      show: _vm.modalShow,
      websiteList: _vm.websiteList,
      permissionName: _vm.permissionName,
    },
    on: {
      "update:show": function ($event) {
        _vm.modalShow = $event
      },
      ok: _vm.websiteEditOk,
      cancel: _vm.websiteEditCancel,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }