<template>
  <WebsitesModal :show.sync="modalShow" :websiteList="websiteList" :permissionName="permissionName"
    @ok="websiteEditOk" @cancel="websiteEditCancel"/>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'WebsitesEditor',
  components: {
    WebsitesModal: () => import('@/components/modal/WebsitesModal')
  },
  data() {
    return {
      value: []
      , modalShow: false
      , websiteList: []
      , permissionName: null
    }
  },
  computed: {
  },
  methods: {
    getValue() {
      return this.value
    },
    isPopup() {
      return true
    },
    isCancelBeforeStart() {
      return false
    },

    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing()
      }, 100)
    },
    websiteEditOk({ websiteList=null } = {}) {
      if (Array.isArray(websiteList)) {
        this.value = websiteList
      }
      this.closePicker()
    },
    
    prepareWebsiteList() {
      if(this.value != null) {
        for (const website of this.value) {
          this.websiteList.push(website)
        }
      }
    },
    websiteEditCancel() {
      this.closePicker()
    }
  },

  created() {
    this.modalShow = true
    if (this.params.permissionName != null) {
      this.permissionName = this.params.permissionName
    }
    this.value = this.params.value
    this.prepareWebsiteList()
    
  }
})
</script>