<template>
  <SocialsModal :show.sync="modalShow" :socialList="socialList" :permissionName="permissionName"
    @ok="socialEditOk" @cancel="socialEditCancel"/>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'SocialsEditor',
  components: {
    SocialsModal: () => import('@/components/modal/SocialsModal')
  },
  data() {
    return {
      value: []
      , modalShow: false
      , socialList: []
      , permissionName: null
    }
  },
  computed: {
  },
  methods: {
    getValue() {
      return this.value
    },
    isPopup() {
      return true
    },
    isCancelBeforeStart() {
      return false
    },

    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing()
      }, 100)
    },
    socialEditOk({ socialList=null } = {}) {
      if (Array.isArray(socialList)) {
        this.value = socialList
      }
      this.closePicker()
    },    
    prepareSocialList() {
      if(this.value != null) {
        for (const social of this.value) {
          this.socialList.push(social)
        }
      }
    },
    socialEditCancel() {
      this.closePicker()
    }
  },

  created() {
    this.modalShow = true
    if (this.params.permissionName != null) {
      this.permissionName = this.params.permissionName
    }
    this.value = this.params.value
    this.prepareSocialList()
    
  }
})
</script>